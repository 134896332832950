/* ACTION TYPES */
import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_RESET,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PROFILE_RESET,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
  USER_LIST_RESET,
  USER_DELETE_REQUEST,
  USER_DELETE_FAIL,
  USER_DELETE_SUCCESS,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_UPDATE_RESET,

  OTP_REQUEST,
  OTP_REQUEST_SUCCESS,
  OTP_REQUEST_FAIL,
  OTP_VERIFY_REQUEST,
  OTP_VERIFY_SUCCESS,
  OTP_VERIFY_FAIL,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
} from '../../constants/categoryConstants';

/* REDUCER USED IN USER LOGIN IN LoginScreen COMPONENT */
// reducers: userReducers.js
// User login reducer
export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true };

    case USER_LOGIN_SUCCESS:
      return { loading: false, userInfo: action.payload };

    case USER_LOGIN_FAIL:
      return { loading: false, error: action.payload };

    case USER_LOGOUT:
      return {};

    default:
      return state;
  }
};

// OTP request reducer
export const otpRequestReducer = (state = {}, action) => {
  switch (action.type) {
    case OTP_REQUEST:
      return { loading: true };

    case OTP_REQUEST_SUCCESS:
      return { loading: false, otpSent: true };

    case OTP_REQUEST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

// OTP verify reducer
export const otpVerifyReducer = (state = {}, action) => {
  switch (action.type) {
    case OTP_VERIFY_REQUEST:
      return { loading: true };

    case OTP_VERIFY_SUCCESS:
      return { loading: false, otpVerified: true, userInfo: action.payload };

    case OTP_VERIFY_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

/* REDUCER USED IN USER REGISTRATION IN RegisterScreen COMPONENT */
// export const userRegisterReducer = (state = {}, action) => {
//   switch (action.type) {
//     case USER_REGISTER_REQUEST:
//       return {
//         loading: true,
//       };

//     case USER_REGISTER_SUCCESS:
//       return {
//         loading: false,
//         userInfo: action.payload,
//       };

//     case USER_REGISTER_FAIL:
//       return {
//         loading: false,
//         error: action.payload,
//       };

//     case USER_LOGOUT:
//       return {}; /* CLEARS STATE */

//     default:
//       return state;
//   }
// };


export const userRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return { loading: true };
    case USER_REGISTER_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_REGISTER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

/* REDUCER USED IN GETTING USER DETAILS IN ProfileScreen COMPONENT */
export const userDetailsReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case USER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case USER_DETAILS_SUCCESS:
      return {
        loading: false,
        user: action.payload,
      };

    case USER_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case USER_DETAILS_RESET:
      return {
        user: {},
      };

    default:
      return state;
  }
};

/* REDUCER USED IN UPDATING USER DETAILS IN ProfileScreen COMPONENT */
export const userUpdateProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_PROFILE_REQUEST:
      return {
        loading: true,
      };

    case USER_UPDATE_PROFILE_SUCCESS:
      return {
        loading: false,
        success: true,
        user: action.payload,
      };

    case USER_UPDATE_PROFILE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case USER_UPDATE_PROFILE_RESET:
      return {}; /* RESET STATE */

    default:
      return state;
  }
};

/* REDUCER USED TO GET LIST OF USERS IN UserList SCREEN */
export const userListReducer = (state = { users: [] }, action) => {
  switch (action.type) {
    case USER_LIST_REQUEST:
      return {
        loading: true,
      };

    case USER_LIST_SUCCESS:
      return {
        loading: false,
        users: action.payload,
      };

    case USER_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case USER_LIST_RESET:
      return { users: [] };

    default:
      return state;
  }
};

/* REDUCER USED TO DELETE A USER FROM UserList SCREEN */
export const userDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_DELETE_REQUEST:
      return {
        loading: true,
      };

    case USER_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
      };

    case USER_DELETE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};


/* REDUCER USED TO EDIT A USER IN UserEdit SCREEN */
export const userUpdateReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case USER_UPDATE_REQUEST:
      return {
        loading: true,
      };
    case USER_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case USER_UPDATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case USER_UPDATE_RESET:
      return {
        user: {},
      };
    default:
      return state;
  }
};


export const changePasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case CHANGE_PASSWORD_REQUEST:
      return { loading1: true };
    case CHANGE_PASSWORD_SUCCESS:
      return { loading1: false, successMessage: action.payload };
    case CHANGE_PASSWORD_FAIL:
      return { loading1: false, error1: action.payload };
    default:
      return state;
  }
};


