import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false,
  userInfoLocal: JSON.parse(localStorage.getItem("userInfo")) || null, // Load initial state from localStorage if available
};

export const isOpenSlice = createSlice({
  name: 'isOpen',
  initialState,
  reducers: {
    isLoginAction: (state, action) => {
      state.isOpen = action.payload;
    },
    setUserInfo: (state, action) => {
      state.userInfoLocal = action.payload;
      localStorage.setItem("userInfo", JSON.stringify(action.payload)); // Persist user info in localStorage
    },
    logout: (state) => {
      state.userInfoLocal = null;
      localStorage.removeItem("userInfo"); // Clear user info from localStorage on logout
    },
  },
});

export const { isLoginAction, setUserInfo, logout } = isOpenSlice.actions;

export const isOpenReducer = isOpenSlice.reducer;
