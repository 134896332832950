import { Category } from '@mui/icons-material'
import { 
    CATEGORY_PRODUCT_MAPING_LIST_REQUEST,
    CATEGORY_PRODUCT_MAPING_LIST_SUCCESS,
    CATEGORY_PRODUCT_MAPING_LIST_FAIL,
    CATEGORY_PRODUCT_MAPING_LIST_DETAILS_REQUEST,
    CATEGORY_PRODUCT_MAPING_LIST_DETAILS_SUCCESS,
    CATEGORY_PRODUCT_MAPING_LIST_DETAILS_FAIL
 } from '../../constants/categoryConstants'


export const CategoryLocationMapingListReducers =(state={categoryLocationMaping:[]},action)=>{
    switch(action.type){
        case CATEGORY_PRODUCT_MAPING_LIST_REQUEST:
            return {laoding:true,categoryLocationMaping:[]}
        case CATEGORY_PRODUCT_MAPING_LIST_SUCCESS:
            return {laoding:false,categoryLocationMaping:action.payload}
        case CATEGORY_PRODUCT_MAPING_LIST_FAIL:
            return {laoding:false,error: action.payload}
        default:
            return state
    }
}

export const productsBycategoryDetailsReducers = (state={ categoryLocationMaping:{reviews:[]} },action) =>{
    switch(action.type){
        case CATEGORY_PRODUCT_MAPING_LIST_DETAILS_REQUEST:
            return {loading:true,...state}
        case CATEGORY_PRODUCT_MAPING_LIST_DETAILS_SUCCESS:
            return {loading:false,categoryLocationMaping:action.payload}
        case CATEGORY_PRODUCT_MAPING_LIST_DETAILS_FAIL:
            return {loading:false, error: action.payload }
        default:
            return state
    }

}