export const CATEGORY_LIST_REQUEST = 'CATEGORY_LIST_REQUEST'
export const CATEGORY_LIST_SUCCESS = 'CATEGORY_LIST_SUCCESS'
export const CATEGORY_LIST_FAIL = 'CATEGORY_LIST_FAIL'

export const CATEGORY_DETAILS_REQUEST= 'CATEGORY_DETAILS_REQUEST'
export const CATEGORY_DETAILS_SUCCESS ='CATEGORY_DETAILS_SUCCESS'
export const CATEGORY_DETAILS_FAIL = 'CATEGORY_DETAILS_FAIL'

export const LOCATION_LIST_REQUEST = 'LOCATION_LIST_REQUEST'
export const LOCATION_LIST_SUCCESS = 'LOCATION_LIST_SUCCESS'
export const LOCATION_LIST_FAIL = 'LOCATION_LIST_FAIL'

export const LOCATION_DETAILS_REQUEST= 'LOCATION_DETAILS_REQUEST'
export const LOCATION_DETAILS_SUCCESS ='LOCATION_DETAILS_SUCCESS'
export const LOCATION_DETAILS_FAIL = 'LOCATION_DETAILS_FAIL'



export const OUR_TEAM_LIST_REQUEST = 'OUR_TEAM_LIST_REQUEST'
export const OUR_TEAM_LIST_SUCCESS = 'OUR_TEAM_LIST_SUCCESS'
export const OUR_TEAM_LIST_FAIL = 'OUR_TEAM_LIST_FAIL'

export const OUR_TEAM_DETAILS_REQUEST= 'OUR_TEAM_DETAILS_REQUEST'
export const OUR_TEAM_DETAILS_SUCCESS ='OUR_TEAM_DETAILS_SUCCESS'
export const OUR_TEAM_DETAILS_FAIL = 'OUR_TEAM_DETAILS_FAIL'


export const PRODUCT_BY_CATEGORY_LIST_REQUEST = 'PRODUCT_BY_CATEGORY_LIST_REQUEST'
export const PRODUCT_BY_CATEGORY_LIST_SUCCESS = 'PRODUCT_BY_CATEGORY_LIST_SUCCESS'
export const PRODUCT_BY_CATEGORY_LIST_FAIL = 'PRODUCT_BY_CATEGORY_LIST_FAIL'

export const PRODUCT_BY_CATEGORY_DETAILS_REQUEST= 'PRODUCT_BY_CATEGORY_DETAILS_REQUEST'
export const PRODUCT_BY_CATEGORY_DETAILS_SUCCESS ='PRODUCT_BY_CATEGORY_DETAILS_SUCCESS'
export const PRODUCT_BY_CATEGORY_DETAILS_FAIL = 'PRODUCT_BY_CATEGORY_DETAILS_FAIL'

export const PRODUCT_LIST_REQUEST = 'PRODUCT_LIST_REQUEST'
export const PRODUCT_LIST_SUCCESS = 'PRODUCT_LIST_SUCCESS'
export const PRODUCT_LIST_FAIL = 'PRODUCT_LIST_FAIL'




export const SINGLE_PRODUCT_REQUEST = 'SINGLE_PRODUCT_REQUEST'
export const SINGLE_PRODUCT_SUCCESS = 'SINGLE_PRODUCT_SUCCESS'
export const SINGLE_PRODUCT_FAIL = 'SINGLE_PRODUCT_FAIL'




export const SINGLE_PRODUCT_DETAILS_REQUEST= 'SINGLE_PRODUCT_DETAILS_REQUEST'
export const SINGLE_PRODUCT_DETAILS_SUCCESS ='SINGLE_PRODUCT_DETAILS_SUCCESS'
export const SINGLE_PRODUCT_DETAILS_FAIL = 'ITEM_DETAILS_FAIL'



export const BLOG_LIST_REQUEST = 'BLOG_LIST_REQUEST'
export const BLOG_LIST_SUCCESS = 'BLOG_LIST_SUCCESS'
export const BLOG_LIST_FAIL = 'BLOG_LIST_FAIL'

export const BLOG_DETAILS_REQUEST= 'BLOG_DETAILS_REQUEST'
export const BLOG_DETAILS_SUCCESS = 'BLOG_DETAILS_SUCCESS'
export const BLOG_DETAILS_FAIL = 'BLOG_DETAILS_FAIL'




export const PARTICULAR_PRODUCT_REQUEST = 'PARTICULAR_PRODUCT_REQUEST'
export const PARTICULAR_PRODUCT_SUCCESS = 'PARTICULAR_PRODUCT_SUCCESS'
export const PARTICULAR_PRODUCT_FAIL = 'PARTICULAR_PRODUCT_FAIL'

export const PARTICULAR_PRODUCT_DETAILS_REQUEST= 'PARTICULAR_PRODUCT_DETAILS_REQUEST'
export const PARTICULAR_PRODUCT_DETAILS_SUCCESS ='PARTICULAR_PRODUCT_DETAILS_SUCCESS'
export const PARTICULAR_PRODUCT_DETAILS_FAIL = 'ITEM_DETAILS_FAIL'



export const SLIDER_LIST_REQUEST = 'SLIDER_LIST_REQUEST'
export const SLIDER_LIST_SUCCESS = 'SLIDER_LIST_SUCCESS'
export const SLIDER_LIST_FAIL = 'SLIDER_LIST_FAIL'

export const SLIDER_DETAILS_REQUEST= 'SLIDER_DETAILS_REQUEST'
export const SLIDER_DETAILS_SUCCESS ='SLIDER_DETAILS_SUCCESS'
export const SLIDER_DETAILS_FAIL = 'SLIDER_DETAILS_FAIL'

export const COUPON_LIST_REQUEST = 'COUPON_LIST_REQUEST'
export const COUPON_LIST_SUCCESS = 'COUPON_LIST_SUCCESS'
export const COUPON_LIST_FAIL = 'COUPON_LIST_FAIL'

export const COUPON_DETAILS_REQUEST= 'COUPON_DETAILS_REQUEST'
export const COUPON_DETAILS_SUCCESS ='COUPON_DETAILS_SUCCESS'
export const COUPON_DETAILS_FAIL = 'COUPON_DETAILS_FAIL'


export const REVIEW_LIST_REQUEST = 'REVIEW_LIST_REQUEST'
export const REVIEW_LIST_SUCCESS = 'REVIEW_LIST_SUCCESS'
export const REVIEW_LIST_FAIL = 'REVIEW_LIST_FAIL'

export const REVIEW_DETAILS_REQUEST= 'REVIEW_DETAILS_REQUEST'
export const REVIEW_DETAILS_SUCCESS ='REVIEW_DETAILS_SUCCESS'
export const REVIEW_DETAILS_FAIL = 'REVIEW_DETAILS_FAIL'


export const CONTACT_US_REQUEST = 'CONTACT_US_REQUEST'
export const CONTACT_US_SUCCESS = 'CONTACT_US_SUCCESS'
export const CONTACT_US_FAIL = 'CONTACT_US_FAIL'



export const CHECK_OUT_LIST_REQUEST = 'CHECK_OUT_LIST_REQUEST'
export const CHECK_OUT_LIST_SUCCESS = 'CHECK_OUT_LIST_SUCCESS'
export const CHECK_OUT_LIST_FAIL = 'CHECK_OUT_LIST_FAIL'


export const CHECK_OUT_REQUEST = 'CHECK_OUT_REQUEST'
export const CHECK_OUT_SUCCESS = 'CHECK_OUT_SUCCESS'
export const CHECK_OUT_FAIL = 'CHECK_OUT_FAIL'



export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST'
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL'



export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST'
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS'
export const UPDATE_PROFILE_FAIL = 'UPDATE_PROFILE_FAIL'




/* ACTION TYPES */



export const USER_LOGIN_TOKEN_REQUEST = "USER_LOGIN_TOKEN_REQUEST";
export const USER_LOGIN_TOKEN_SUCCESS = "USER_LOGIN_TOKEN_SUCCESS";
export const USER_LOGIN_TOKEN_FAIL = "USER_LOGIN_TOKEN_FAIL"

// LOGIN
export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";

// LOGOUT
export const USER_LOGOUT = "USER_LOGOUT";

// OTP
export const OTP_REQUEST = 'OTP_REQUEST';
export const OTP_REQUEST_SUCCESS = 'OTP_REQUEST_SUCCESS';
export const OTP_REQUEST_FAIL = 'OTP_REQUEST_FAIL';

export const OTP_VERIFY_REQUEST = 'OTP_VERIFY_REQUEST';
export const OTP_VERIFY_SUCCESS = 'OTP_VERIFY_SUCCESS';
export const OTP_VERIFY_FAIL = 'OTP_VERIFY_FAIL';


// REGISTER
export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";

// USER DETAILS
export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST";
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_FAIL = "USER_DETAILS_FAIL";
export const USER_DETAILS_RESET = "USER_DETAILS_RESET";

// UPDATE USER DETAILS
export const USER_UPDATE_PROFILE_REQUEST = "USER_UPDATE_PROFILE_REQUEST";
export const USER_UPDATE_PROFILE_SUCCESS = "USER_UPDATE_PROFILE_SUCCESS";
export const USER_UPDATE_PROFILE_FAIL = "USER_UPDATE_PROFILE_FAIL";
export const USER_UPDATE_PROFILE_RESET = "USER_UPDATE_PROFILE_RESET";

// USER LIST
export const USER_LIST_REQUEST = "USER_LIST_REQUEST";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_FAIL = "USER_LIST_FAIL";
export const USER_LIST_RESET = "USER_LIST_RESET";

// USER DELETE
export const USER_DELETE_REQUEST = "USER_DELETE_REQUEST";
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";
export const USER_DELETE_FAIL = "USER_DELETE_FAIL";

// USER EDIT
export const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAIL = "USER_UPDATE_FAIL";
export const USER_UPDATE_RESET = "USER_UPDATE_RESET";


export const CATEGORY_PRODUCT_MAPING_LIST_REQUEST = 'CATEGORY_PRODUCT_MAPING_LIST_REQUEST'
export const CATEGORY_PRODUCT_MAPING_LIST_SUCCESS = 'CATEGORY_PRODUCT_MAPING_LIST_SUCCESS'
export const CATEGORY_PRODUCT_MAPING_LIST_FAIL = 'CATEGORY_PRODUCT_MAPING_LIST_FAIL'

export const CATEGORY_PRODUCT_MAPING_LIST_DETAILS_REQUEST= 'CATEGORY_PRODUCT_MAPING_LIST_DETAILS_REQUEST'
export const CATEGORY_PRODUCT_MAPING_LIST_DETAILS_SUCCESS ='CATEGORY_PRODUCT_MAPING_LIST_DETAILS_SUCCESS'
export const CATEGORY_PRODUCT_MAPING_LIST_DETAILS_FAIL = 'CATEGORY_PRODUCT_MAPING_LIST_DETAILS_FAIL'





export const CATEGORY_PRODUCT_MAPING_ADD_REQUEST = 'CATEGORY_PRODUCT_MAPING_ADD_REQUEST'
export const CATEGORY_PRODUCT_MAPING_ADD_SUCCESS = 'CATEGORY_PRODUCT_MAPING_ADD_SUCCESS'
export const CATEGORY_PRODUCT_MAPING_ADD_FAIL = 'CATEGORY_PRODUCT_MAPING_ADD_FAIL'


export const CATEGORY_PRODUCT_MAPING_ADD_DETAILS_REQUEST= 'CATEGORY_PRODUCT_MAPING_ADD_DETAILS_REQUEST'
export const CATEGORY_PRODUCT_MAPING_ADD_DETAILS_SUCCESS ='CATEGORY_PRODUCT_MAPING_ADD_DETAILS_SUCCESS'
export const CATEGORY_PRODUCT_MAPING_ADD_DETAILS_FAIL = 'CATEGORY_PRODUCT_MAPING_ADD_DETAILS_FAIL'



// Privacy Policy

export const PRIVACY_POLICY_LIST_REQUEST = 'PRIVACY_POLICY_LIST_REQUEST'
export const PRIVACY_POLICY_LIST_SUCCESS = 'PRIVACY_POLICY_LIST_SUCCESS'
export const PRIVACY_POLICY_LIST_FAIL = 'PRIVACY_POLICY_LIST_FAIL'

export const PRIVACY_POLICY_DETAILS_REQUEST= 'PRIVACY_POLICY_DETAILS_REQUEST'
export const PRIVACY_POLICY_DETAILS_SUCCESS = 'PRIVACY_POLICY_DETAILS_SUCCESS'
export const PRIVACY_POLICY_DETAILS_FAIL = 'PRIVACY_POLICY_DETAILS_FAIL'

// Faqs

export const FAQS_LIST_REQUEST = 'FAQS_LIST_REQUEST'
export const FAQS_POLICY_LIST_SUCCESS = 'FAQS_POLICY_LIST_SUCCESS'
export const FAQS_POLICY_LIST_FAIL = 'FAQS_POLICY_LIST_FAIL'

export const FAQS_POLICY_DETAILS_REQUEST= 'FAQS_POLICY_DETAILS_REQUEST'
export const FAQS_POLICY_DETAILS_SUCCESS = 'FAQS_POLICY_DETAILS_SUCCESS'
export const FAQS_POLICY_DETAILS_FAIL = 'FAQS_POLICY_DETAILS_FAIL'