import { CATEGORY_LIST_REQUEST,CATEGORY_LIST_SUCCESS,CATEGORY_LIST_FAIL,   CATEGORY_DETAILS_REQUEST,CATEGORY_DETAILS_SUCCESS,
    CATEGORY_DETAILS_FAIL } from '../../constants/categoryConstants'

export const categoryListReducers =(state={category:[]},action)=>{

    switch(action.type){
        case CATEGORY_LIST_REQUEST:
            return {laoding:true,category:[]}
        case CATEGORY_LIST_SUCCESS:
            return {laoding:false,category:action.payload}
        case CATEGORY_LIST_FAIL:
            return {laoding:false,error: action.payload}

        default:
            return state
    }



}
export const categoryDetailsReducers = (state={ category:{reviews:[]} },action) =>{

    switch(action.type){
        case CATEGORY_DETAILS_REQUEST:
            return {loading:true,...state}
        case CATEGORY_DETAILS_SUCCESS:
            return {loading:false,category:action.payload}
        case CATEGORY_DETAILS_FAIL:
            return {loading:false, error: action.payload }

        default:
            return state
    }

}