import { CONTACT_US_FAIL, CONTACT_US_REQUEST, CONTACT_US_SUCCESS } from "../../constants/categoryConstants";

 
/* REDUCER Contact Us IN USER REGISTRATION IN RegisterScreen COMPONENT */
export const contactusFormReducers = (state = {}, action) => {
    switch (action.type) {
      case CONTACT_US_REQUEST:
        return {
          loading: true,
        };
  
      case CONTACT_US_SUCCESS:
        return {
          loading: false,
          contactInfo: action.payload,
        };
  
      case CONTACT_US_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
  

  
      default:
        return state;
    }
  };