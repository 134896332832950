import { 
  CATEGORY_PRODUCT_MAPING_ADD_REQUEST, CATEGORY_PRODUCT_MAPING_ADD_SUCCESS, CATEGORY_PRODUCT_MAPING_ADD_FAIL,
  CATEGORY_PRODUCT_MAPING_LIST_REQUEST, CATEGORY_PRODUCT_MAPING_LIST_SUCCESS, CATEGORY_PRODUCT_MAPING_LIST_FAIL,
} from "../../constants/categoryConstants";

 

export const orderPlaceListReducers =(state={orderPlace:[]},action)=>{
  switch(action.type){
      case CATEGORY_PRODUCT_MAPING_LIST_REQUEST:
          return {laoding:true,orderPlace:[]}
      case CATEGORY_PRODUCT_MAPING_LIST_SUCCESS:
          return {laoding:false,orderPlace:action.payload}
      case CATEGORY_PRODUCT_MAPING_LIST_FAIL:
          return {laoding:false,error: action.payload}

      default:
          return state
  }
}
/* REDUCER Contact Us IN USER REGISTRATION IN RegisterScreen COMPONENT */
export const orderPlaceFormReducers = (state = {}, action) => {
    switch (action.type) {
      case CATEGORY_PRODUCT_MAPING_ADD_REQUEST:
        return {
          loading: true,
        };
  
      case CATEGORY_PRODUCT_MAPING_ADD_SUCCESS:
        return {
          loading: false,
          contactInfo: action.payload,
        };
  
      case CATEGORY_PRODUCT_MAPING_ADD_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
  
      default:
        return state;
    }
  };