import {
    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAIL,
} from "../../constants/categoryConstants";
 
/* REDUCER Contact Us IN USER REGISTRATION IN RegisterScreen COMPONENT */
export const changePasswordFormReducers = (state = {}, action) => {
    switch (action.type) {
      case CHANGE_PASSWORD_REQUEST:
        return {
          loading: true,
        };
  
      case CHANGE_PASSWORD_SUCCESS:
        return {
          loading: false,
          chnagePasswordInfo: action.payload,
        };
  
      case CHANGE_PASSWORD_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
  

  
      default:
        return state;
    }
  };