import { CHECK_OUT_REQUEST, CHECK_OUT_SUCCESS, CHECK_OUT_FAIL,
    CHECK_OUT_LIST_REQUEST,
    CHECK_OUT_LIST_SUCCESS,
    CHECK_OUT_LIST_FAIL,
 } from "../../constants/categoryConstants";

 
/* REDUCER Contact Us IN USER REGISTRATION IN RegisterScreen COMPONENT */


export const checkOutListReducers =(state={checkOut:[]},action)=>{
  switch(action.type){
      case CHECK_OUT_LIST_REQUEST:
          return {laoding:true,checkOut:[]}
      case CHECK_OUT_LIST_SUCCESS:
          return {laoding:false,checkOut:action.payload}
      case CHECK_OUT_LIST_FAIL:
          return {laoding:false,error: action.payload}
      default:
          return state
  }
}


export const checkOutFormReducers = (state = {}, action) => {
    switch (action.type) {
      case CHECK_OUT_REQUEST:
        return {
          loading: true,
        };
      case CHECK_OUT_SUCCESS:
        return {
          loading: false,
          checkOutInfo: action.payload,
        };
      case CHECK_OUT_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
  

  
      default:
        return state;
    }
  };