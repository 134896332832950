import { 
    REVIEW_LIST_REQUEST,
    REVIEW_LIST_SUCCESS,
    REVIEW_LIST_FAIL,
    REVIEW_DETAILS_REQUEST,
    REVIEW_DETAILS_SUCCESS,
    REVIEW_DETAILS_FAIL
} from '../../constants/categoryConstants'
    
export const reviewListReducers =(state={review:[]},action)=>{
    switch(action.type){
        case REVIEW_LIST_REQUEST:
            return {laoding:true,review:[]}
        case REVIEW_LIST_SUCCESS:
            return {laoding:false,review:action.payload}
        case REVIEW_LIST_FAIL:
            return {laoding:false,error: action.payload}
        default:
            return state
    }
}


export const reviewDetailsReducers = (state={ review:{reviews:[]} },action) =>{
    switch(action.type){
        case REVIEW_DETAILS_REQUEST:
            return {loading:true,...state}
        case REVIEW_DETAILS_SUCCESS:
            return {loading:false,review:action.payload}
        case REVIEW_DETAILS_FAIL:
            return {loading:false, error: action.payload }

        default:
            return state
    }

}