import { 
    FAQS_LIST_REQUEST,
    FAQS_POLICY_LIST_SUCCESS,
    FAQS_POLICY_LIST_FAIL,
    FAQS_POLICY_DETAILS_REQUEST,
    FAQS_POLICY_DETAILS_SUCCESS,
    FAQS_POLICY_DETAILS_FAIL
    } from '../../constants/categoryConstants'

export const FaqsQAListReducers =(state={faqsQA:[]},action)=>{
    switch(action.type){
        case FAQS_LIST_REQUEST:
            return {laoding:true,faqsQA:[]}
        case FAQS_POLICY_LIST_SUCCESS:
            return {laoding:false,faqsQA:action.payload}
        case FAQS_POLICY_LIST_FAIL:
            return {laoding:false,error: action.payload}
        default:
            return state
    }
}

export const FaqsQADetailsReducers = (state={ faqsQA:{reviews:[]} },action) =>{
    switch(action.type){
        case FAQS_POLICY_DETAILS_REQUEST:
            return {loading:true,...state}
        case FAQS_POLICY_DETAILS_SUCCESS:
            return {loading:false,faqsQA:action.payload}
        case FAQS_POLICY_DETAILS_FAIL:
            return {loading:false, error: action.payload }
        default:
            return state
    }
}