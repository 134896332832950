import { LOCATION_LIST_REQUEST,LOCATION_LIST_SUCCESS,LOCATION_LIST_FAIL,   LOCATION_DETAILS_REQUEST,LOCATION_DETAILS_SUCCESS,
    LOCATION_DETAILS_FAIL } from '../../constants/categoryConstants'

export const locationListReducers =(state={location:[]},action)=>{

    switch(action.type){
        case LOCATION_LIST_REQUEST:
            return {laoding:true,location:[]}
        case LOCATION_LIST_SUCCESS:
            return {laoding:false,location:action.payload}
        case LOCATION_LIST_FAIL:
            return {laoding:false,error: action.payload}

        default:
            return state
    }
}
export const locationDetailsReducers = (state={ location:{reviews:[]} },action) =>{

    switch(action.type){
        case LOCATION_DETAILS_REQUEST:
            return {loading:true,...state}
        case LOCATION_DETAILS_SUCCESS:
            return {loading:false,location:action.payload}
        case LOCATION_DETAILS_FAIL:
            return {loading:false, error: action.payload }

        default:
            return state
    }

}