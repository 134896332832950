import { 
    PRODUCT_BY_CATEGORY_LIST_REQUEST,
    PRODUCT_BY_CATEGORY_LIST_SUCCESS,
    PRODUCT_BY_CATEGORY_LIST_FAIL,
    PRODUCT_BY_CATEGORY_DETAILS_REQUEST,
    PRODUCT_BY_CATEGORY_DETAILS_SUCCESS,
    PRODUCT_BY_CATEGORY_DETAILS_FAIL,

    PRODUCT_LIST_REQUEST,
    PRODUCT_LIST_SUCCESS,
    PRODUCT_LIST_FAIL,
    
 } from '../../constants/categoryConstants'


export const productListReducers =(state={products:[]},action)=>{
    switch(action.type){
        case PRODUCT_LIST_REQUEST:
            return {laoding:true,products:[]}
        case PRODUCT_LIST_SUCCESS:
            return {laoding:false,products:action.payload}
        case PRODUCT_LIST_FAIL:
            return {laoding:false,error: action.payload}
        default:
            return state
    }
}



export const productsBycategoryListReducers =(state={productsBycategory:[]},action)=>{

    switch(action.type){
        case PRODUCT_BY_CATEGORY_LIST_REQUEST:
            return {laoding:true,productsBycategory:[]}
        case PRODUCT_BY_CATEGORY_LIST_SUCCESS:
            return {laoding:false,productsBycategory:action.payload}
        case PRODUCT_BY_CATEGORY_LIST_FAIL:
            return {laoding:false,error: action.payload}

        default:
            return state
    }
}


export const productsBycategoryDetailsReducers = (state={ productsBycategory:{reviews:[]} },action) =>{

    switch(action.type){
        case PRODUCT_BY_CATEGORY_DETAILS_REQUEST:
            return {loading:true,...state}
        case PRODUCT_BY_CATEGORY_DETAILS_SUCCESS:
            return {loading:false,productsBycategory:action.payload}
        case PRODUCT_BY_CATEGORY_DETAILS_FAIL:
            return {loading:false, error: action.payload }

        default:
            return state
    }

}