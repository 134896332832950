import { 
    PARTICULAR_PRODUCT_REQUEST,
    PARTICULAR_PRODUCT_SUCCESS ,
    PARTICULAR_PRODUCT_FAIL,
    PARTICULAR_PRODUCT_DETAILS_REQUEST,
    PARTICULAR_PRODUCT_DETAILS_SUCCESS ,
    PARTICULAR_PRODUCT_DETAILS_FAIL 
 } from '../../constants/categoryConstants'

export const singleProductListReducers =(state={singleProduct:[]},action)=>{
    switch(action.type){
        case PARTICULAR_PRODUCT_REQUEST:
            return {laoding:true,singleProduct:[]}
        case PARTICULAR_PRODUCT_SUCCESS:
            return {laoding:false,singleProduct:action.payload}
        case PARTICULAR_PRODUCT_FAIL:
            return {laoding:false,error: action.payload}
        default:
            return state
    }
}

export const singleProductDetailsReducers = (state={ singleProduct:{reviews:[]} },action) =>{
    switch(action.type){
        case PARTICULAR_PRODUCT_DETAILS_REQUEST:
            return {loading:true,...state}
        case PARTICULAR_PRODUCT_DETAILS_SUCCESS:
            return {loading:false,singleProduct:action.payload}
        case PARTICULAR_PRODUCT_DETAILS_FAIL:
            return {loading:false, error: action.payload }
        default:
            return state
    }
}