import { createSlice } from "@reduxjs/toolkit";
import { getProductByChildrenCategory, getProductByName, getProductByParentCategory } from "../../fakeData/Products";

const initialState = {
  value: null,
  path: null,
  product: [],
};

export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    searchAction: (state, action) => {
      state.path = action.payload.path;
      state.value = action.payload.value;

      // Default to an empty array if no product found
      if (state.path === "query") {
        state.product = (getProductByName(state.value) || [])?.result;
      } else if (state.path === "category") {
        state.product = (getProductByParentCategory(state.value) || [])?.result;
      } else if (state.path === "item") {
        state.product = (getProductByChildrenCategory(state.value) || [])?.result;
      }

     
    },
  },
});

export const { searchAction } = searchSlice.actions;
export const searchReducer = searchSlice.reducer;
