import { 
    OUR_TEAM_LIST_REQUEST, OUR_TEAM_LIST_SUCCESS, OUR_TEAM_LIST_FAIL,
    OUR_TEAM_DETAILS_REQUEST, OUR_TEAM_DETAILS_SUCCESS, OUR_TEAM_DETAILS_FAIL,
 } from '../../constants/categoryConstants'

export const ourTeamListReducers =(state={ourTeam:[]},action)=>{

    switch(action.type){
        case OUR_TEAM_LIST_REQUEST:
            return {laoding:true,ourTeam:[]}
        case OUR_TEAM_LIST_SUCCESS:
            return {laoding:false,ourTeam:action.payload}
        case OUR_TEAM_LIST_FAIL:
            return {laoding:false,error: action.payload}

        default:
            return state
    }
}


export const ourTeamDetailsReducers = (state={ ourTeam:{reviews:[]} },action) =>{

    switch(action.type){
        case OUR_TEAM_DETAILS_REQUEST:
            return {loading:true,...state}
        case OUR_TEAM_DETAILS_SUCCESS:
            return {loading:false,ourTeam:action.payload}
        case OUR_TEAM_DETAILS_FAIL:
            return {loading:false, error: action.payload }

        default:
            return state
    }

}