import React, { lazy, Fragment, useState, Suspense } from "react";
import { Outlet } from 'react-router-dom';
const Header = lazy(() => import("../components/header/Header"));
const Navigation = lazy(() => import("../components/navigation/Navigation"));
const TawkToChat = lazy(() => import("../components/TawkToChat/TawkToChat"));
const SideBar = lazy(() => import("../components/sideBar/SideBar"));
const DrawerCart = lazy(() => import("../components/drawer/Drawer"));
const Login = lazy(() => import("../components/login/Login"));
const Register = lazy(() => import("../components/register/Register"));
const MobileApp = lazy(() => import("../components/mobileApp/MobileApp"));
const Footer = lazy(() => import("../components/footer/Footer"));

const AuthLayout = ({ isOpenRegister, setIsOpenRegister }) => {
  const [isSideBarOpen, setIsSideBarOpen] = useState(false); // Track Sidebar visibility

  return (
    <div className="auth-layout">
      <Fragment>
        <Suspense fallback={<div>Loading Header...</div>}>
          <Header />
        </Suspense>
        <Suspense fallback={<div>Loading Navigation...</div>}>
          <Navigation />
        </Suspense>
        <Suspense fallback={<div>Loading Chat...</div>}>
          <TawkToChat />
        </Suspense>
        
        <Outlet />

        <Suspense fallback={<div>Loading Extras...</div>}>
          {/* Pass `show` to the SideBar */}
          <SideBar show={isSideBarOpen} setIsSideBarOpen={setIsSideBarOpen} />
          <DrawerCart />
          <Login setIsOpenRegister={setIsOpenRegister} />
          <Register isOpen={isOpenRegister} setIsOpenRegister={setIsOpenRegister} />
        </Suspense>

        <div className="w-full">
          <br /><br /><br /><br /><br /><br />
          <Suspense fallback={<div>Loading Footer...</div>}>
            {/* <MobileApp /> */}
            <Footer />
          </Suspense>
        </div>
      </Fragment>
    </div>
  );
};

export default AuthLayout;
