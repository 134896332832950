import { 
    PRIVACY_POLICY_LIST_REQUEST,
    PRIVACY_POLICY_LIST_SUCCESS,
    PRIVACY_POLICY_LIST_FAIL,
    PRIVACY_POLICY_DETAILS_REQUEST,
    PRIVACY_POLICY_DETAILS_SUCCESS,
    PRIVACY_POLICY_DETAILS_FAIL
    } from '../../constants/categoryConstants'

export const privacyPolicyListReducers =(state={privacyPolicy:[]},action)=>{
    switch(action.type){
        case PRIVACY_POLICY_LIST_REQUEST:
            return {laoding:true,privacyPolicy:[]}
        case PRIVACY_POLICY_LIST_SUCCESS:
            return {laoding:false,privacyPolicy:action.payload}
        case PRIVACY_POLICY_LIST_FAIL:
            return {laoding:false,error: action.payload}
        default:
            return state
    }
}

export const privacyPolicyDetailsReducers = (state={ privacyPolicy:{reviews:[]} },action) =>{
    switch(action.type){
        case PRIVACY_POLICY_DETAILS_REQUEST:
            return {loading:true,...state}
        case PRIVACY_POLICY_DETAILS_SUCCESS:
            return {loading:false,privacyPolicy:action.payload}
        case PRIVACY_POLICY_DETAILS_FAIL:
            return {loading:false, error: action.payload }
        default:
            return state
    }

}