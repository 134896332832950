import { 
    SLIDER_LIST_REQUEST,
    SLIDER_LIST_SUCCESS,
    SLIDER_LIST_FAIL,
    SLIDER_DETAILS_REQUEST,
    SLIDER_DETAILS_SUCCESS,
    SLIDER_DETAILS_FAIL,
    } from '../../constants/categoryConstants'

export const sliderListReducers =(state={slider:[]},action)=>{

    switch(action.type){
        case SLIDER_LIST_REQUEST:
            return {laoding:true,slider:[]}
        case SLIDER_LIST_SUCCESS:
            return {laoding:false,slider:action.payload}
        case SLIDER_LIST_FAIL:
            return {laoding:false,error: action.payload}

        default:
            return state
    }



}


export const sliderDetailsReducers = (state={ slider:{reviews:[]} },action) =>{

    switch(action.type){
        case SLIDER_DETAILS_REQUEST:
            return {loading:true,...state}
        case SLIDER_DETAILS_SUCCESS:
            return {loading:false,slider:action.payload}
        case SLIDER_DETAILS_FAIL:
            return {loading:false, error: action.payload }

        default:
            return state
    }

}