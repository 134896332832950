import {
    UPDATE_PROFILE_REQUEST,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE_FAIL,
} from "../../constants/categoryConstants";
 


/* REDUCER Contact Us IN USER REGISTRATION IN RegisterScreen COMPONENT */
export const userProfileFormReducers = (state = {}, action) => {
    switch (action.type) {
      case UPDATE_PROFILE_REQUEST:
        return {
          loading: true,
        };
  
      case UPDATE_PROFILE_SUCCESS:
        return {
          loading: false,
          userProfileInfo: action.payload,
        };
  
      case UPDATE_PROFILE_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
  

  
      default:
        return state;
    }
  };